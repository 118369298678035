import cloneDeep from 'lodash/cloneDeep';
import api from '@/factories/api';
import EventBus from '@/factories/eventBus';
import isEmpty from 'lodash/isEmpty';

export default {
  async loadContacts({ commit, getters, dispatch }, data) {
    const {
      filters,
      archived = false,
      fields = [],
      format = 'v2',
      teamFilterOff = false,
    } = data;
    commit('setContactsLoading', true);

    // clear loaded contacts and filters if contacts type changed
    const storedArchived = getters.contactsInfo?.archived || false;
    if ((archived || storedArchived) && archived != storedArchived) {
      dispatch('clearContactsStore');
      filters.page = 1;
      filters.filters = {};
    }

    try {
      const params = { ...filters, archived, fields, format };
      const loadedContacts = await api({ teamFilterOff }).get('contacts', {
        params,
      });
      const currentContacts = getters.contacts;

      let mergedContacts = [];
      if (filters.page > 1) {
        mergedContacts = currentContacts.concat(loadedContacts.results);
      } else {
        mergedContacts = loadedContacts.results;
      }

      commit('setContacts', mergedContacts);
      commit('setContactsInfo', {
        filters: filters,
        count: loadedContacts?.count,
        archived,
      });
    } finally {
      commit('setContactsLoading', false);
    }
  },

  clearContactsStore({ commit }) {
    commit('setContacts', []);
    commit('setContactsInfo', {});
  },

  async loadSingleContact({ commit }, contactId) {
    commit('setContactLoading', true);
    try {
      const contact = await api().get(`contacts/${contactId}`);
      commit('setActiveContact', contact);
      return contact;
    } finally {
      commit('setContactLoading', false);
    }
  },

  async loadSingleContactShort({ commit, getters }, data) {
    const { filters = {}, update = false, format = 'v2' } = data;
    try {
      const res = await api().get('contacts', { params: { filters, format } });
      const contact = Array.isArray(res?.results) ? res.results[0] : null;

      if (update) {
        commit('updateContactInList', contact);
      }

      return contact;
    } catch (err) {
      throw err;
    }
  },

  async loadContactMetric({ commit, getters }, params) {
    try {
      const metricData = await api().get('contacts/metrics', { params });
      const originalContact = getters.singleContact(params.filters.con_id);

      if (originalContact) {
        const contact = Object.assign(cloneDeep(originalContact), metricData);
        commit('updateContactInList', contact);
      }

      return metricData;
    } catch (err) {
      throw err;
    }
  },

  async createContact({ commit, getters }, data) {
    commit('setContactLoading', true);
    try {
      const newContact = await api().post('contacts', data);
      const fields = getters['fields/all'];
      const convertedContact = convertContact(data, fields, newContact);

      // await contact post-creation processing before adding it to the contacts table
      setTimeout(() => {
        commit('updateContactInList', convertedContact);
      });

      return convertedContact;
    } catch (err) {
      throw err;
    } finally {
      commit('setContactLoading', false);
    }
  },

  async updateContact({ commit, dispatch }, { contactId, params }) {
    try {
      await api().put(`contacts/${contactId}`, params);
      dispatch('loadSingleContactShort', {
        filters: { id: contactId },
        update: true,
      });
      return true;
    } catch (err) {
      throw err;
    }
  },

  updateContactField({ getters, dispatch }, { contactId, key, value }) {
    const field = getters['fields/getSingleField'](key);
    if (!field) return;

    const params = { [field.bucketId]: { [field.name]: value } };
    let data = {
      contactId,
      params,
      field,
      value,
    };

    dispatch('updateContact', data);
    EventBus.$emit('contactUpdated', data);
  },

  async deleteContact({ commit, getters }, contactId) {
    try {
      await api().delete(`contacts/${contactId}`);
      let contacts = getters.contacts.filter((i) => i.con_id !== contactId);
      commit('setContacts', contacts);
    } catch (err) {
      throw err;
    }
  },

  async compileMessageText({}, content) {
    try {
      return await api().post('contacts/compile', { content });
    } catch (err) {
      throw err;
    }
  },

  async toggleBlockContactMessages({ commit }, { contact_id, block }) {
    try {
      const type = block ? 'block' : 'unblock';
      await api().post(`contacts/${type}`, { contact_id });
    } catch (err) {
      throw err;
    }
  },

  async fetchContact({ commit }, contactId) {
    try {
      return await api().get(`contacts/${contactId}`);
    } catch (err) {
      throw err;
    }
  },

  async getContactToken({ commit }, contactId) {
    try {
      const contactData = await api().post(`contacts/${contactId}/token`);
      return contactData ? contactData.token : null;
    } catch (err) {
      throw err;
    }
  },

  async loadFilteredContacts({}, { filters, countOnly = false }) {
    try {
      if (countOnly) {
        filters.count = 1;
      }
      const res = await api().get('contacts', { params: filters });
      if (filters.limit === 1) return res?.results[0];
      return res;
    } catch (err) {
      throw err;
    }
  },

  async loadContactSuggestions({ commit }, data) {
    commit('setSuggestionsLoading', true);

    const {
      field,
      query,
      created_after = '',
      shareToken,
      teamFilterOff = false,
    } = data;

    const params = {
      filters: { [field]: { '?': query } },
      field,
      created_after,
      limit: 25,
    };

    const config = { shareToken, teamFilterOff };

    try {
      let suggestions = await api(config).get('contacts/distinct', { params });
      if (!suggestions) return [];

      if (typeof suggestions[0] === 'object') {
        suggestions = suggestions.map((record) => {
          const { name, text, value, id } = record;

          return {
            text: name || text || value,
            value: id || value,
          };
        });
      }

      return suggestions;
    } finally {
      commit('setSuggestionsLoading', false);
    }
  },

  async loadContactNameSuggestions({ commit }, settings) {
    commit('setSuggestionsLoading', true);

    const { query, filters, created_after = '', shareToken } = settings;

    const params = {
      fields: 'name',
      created_after,
      limit: 25,
    };

    if (query) {
      params.search = query;
    }

    if (filters) {
      params.filters = filters;
    }

    try {
      let data = await api({ shareToken }).get('contacts', { params });
      let results = data?.results || [];
      return results.map((contact) => {
        return {
          id: contact.con_id,
          text: contact.name || contact.con_id,
          value: contact.con_id,
        };
      });
    } catch (err) {
      throw err;
    } finally {
      commit('setSuggestionsLoading', false);
    }
  },

  async importContacts({}, url) {
    try {
      return await api().post(`contacts/import`, { url });
    } catch (err) {
      throw err;
    }
  },

  async finishImportContacts({}, { id, fields, tags }) {
    try {
      return await api().put(`contacts/import/${id}`, { fields, tags });
    } catch (err) {
      throw err;
    }
  },

  async getContactsImportStatus({}, id) {
    try {
      return await api().get(`contacts/import/${id}`);
    } catch (err) {
      throw err;
    }
  },

  async loadBoards({ commit, getters }) {
    if (getters.boardsLoaded) return;

    try {
      const boards = await api().get('contacts/boards');
      commit('setBoards', boards);
    } catch (err) {
      throw err;
    } finally {
      commit('setBoardsLoaded', true);
    }
  },

  async compileTextForContact(_, data) {
    const { contactId, content } = data;

    try {
      return await api().post(`contacts/compile/${contactId}`, { content });
    } catch (err) {
      throw err;
    }
  },

  async unarchiveContact({ dispatch }, contactId) {
    await api().post(`contacts/${contactId}/unarchive`);

    dispatch('checkArchivedContact', {
      contactId,
      archived: false,
    });
  },

  async archiveContact({ dispatch }, contactId) {
    await api().post(`contacts/${contactId}/archive`);

    dispatch('checkArchivedContact', {
      contactId,
      archived: true,
    });
  },

  checkArchivedContact({ commit, getters, dispatch }, data) {
    const { archived, contactId } = data;
    if (isEmpty(getters.contactsInfo)) return;

    if (!!getters.contactsInfo?.archived !== archived) {
      let contacts = getters.contacts.filter((i) => i.con_id !== contactId);
      commit('setContacts', contacts);
      return;
    }

    dispatch('loadSingleContactShort', {
      filters: {
        id: contactId,
        archived: archived ? 'just' : '',
      },
      update: true,
    });
  },
};

const convertContact = (contactData, fields, additionalData) => {
  const fieldsObj = fields.find((i) => i.type === 'default')['fields'];
  const convertedObj = Object.keys(contactData).reduce((acc, key) => {
    if (fieldsObj[key]) {
      let keyName = fieldsObj[key].name;
      acc[keyName] = contactData[key];
    }
    return acc;
  }, {});

  return Object.assign(convertedObj, {
    con_id: contactData.id || additionalData.id,
    name: contactData.first_name + ' ' + contactData.last_name,
  });
};
