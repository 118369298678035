<template>
  <div>
    <div class="mb-6" v-if="contactFound">
      <field-block title="Contact already exists" class="mb-3">
        <content-line
          :text="contactFound.$mobile || contactFound.email"
          :selected-text="contactFound.name"
          avatar
          class="base-shadow"
        />
      </field-block>

      <v-btn color="appMain" @click="selectContact">
        {{ returnContact ? 'Select Contact' : 'Open Contact' }}
      </v-btn>
      <hr class="mt-6" />
    </div>

    <contacts-form-fields v-if="contact" :contact="contact" ref="contactForm" />

    <portal to="createContactAction">
      <v-btn
        :color="modal ? 'appMain' : 'primary'"
        class="mr-2"
        @click="saveContact"
        :loading="contactLoading"
      >
        Create
      </v-btn>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';

import ContactsFormFields from '../components/ContactFormFields';

export default {
  name: 'ContactForm',
  components: {
    ContactsFormFields,
  },
  props: {
    returnContact: Boolean,
    modal: Boolean,
    search: String,
  },
  data() {
    return {
      contact: null,
      contactFound: null,
    };
  },
  computed: {
    ...mapGetters(['contactLoading']),
    ...mapGetters('fields', {
      fields: 'all',
      fieldsLoaded: 'loaded',
      getSingleField: 'getSingleField',
    }),
  },
  watch: {
    fieldsLoaded(newVal) {
      if (newVal) {
        this.setContact();
      }
    },
    contact: {
      deep: true,
      handler() {
        if (this.contactFound) {
          this.contactFound = null;
        }
      },
    },
  },
  methods: {
    ...mapActions(['createContact', 'loadSingleContactShort']),
    ...mapActions('fields', { loadFields: 'load' }),
    isValid() {
      return this.$refs.contactForm.validate();
    },

    getTemplate() {
      const fieldsObj = this.fields.find((i) => i.type === 'default')['fields'];
      const fieldsArr = Object.values(fieldsObj)
        .sort((a, b) => a.order - b.order)
        .filter((i) => !i.form_hidden);

      return fieldsArr.reduce((acc, i) => {
        acc[i.name] = i.type === 'list' ? [] : '';
        return acc;
      }, {});
    },

    getContactValue(field, contact) {
      if (!contact) {
        return '';
      }
      if (field.label === 'name') {
        let nameArr = contact[field.label].split(' ');
        return field.name === 'first_name' ? nameArr[0] : nameArr[1];
      }
      return contact[field.label];
    },

    setContact() {
      if (this.fieldsLoaded) {
        this.contact = clone(this.getTemplate());
      }

      if (this.search) {
        let [firstName, lastName] = this.search.trim().split(' ');
        this.contact.first_name = firstName || '';
        this.contact.last_name = lastName || '';
      }
    },

    clearData() {
      for (let key in this.contact) {
        this.contact[key] = Array.isArray(this.contact[key]) ? [] : '';
      }

      this.$refs.contactForm.reset();
    },

    async saveContact() {
      if (!this.isValid()) {
        return;
      }

      await this.findContact();
      if (this.contactFound) return;

      this.createContact(this.contact).then((contact) => {
        this.$emit('created', contact);
      });
    },

    selectContact() {
      if (!this.contactFound) return;

      if (this.returnContact) {
        this.$emit('created', cloneDeep(this.contactFound));
      } else {
        this.parentNavigate(`/contacts/${this.contactFound.con_id}`);
      }
    },

    async findContact() {
      if (this.contactFound) return;

      const fields = ['mobile', 'email'];
      let filters = { $or: [] };

      fields.forEach((f) => {
        if (this.contact[f]) {
          const { bucketId, name } = this.getSingleField(f) || {};
          filters.$or.push({
            [`${bucketId}.${name}`]: {
              $in: this.contact[f],
            },
          });
        }
      });

      this.contactFound = await this.loadSingleContactShort({ filters });
    },
  },
  mounted() {
    this.loadFields({ checkLoaded: true });
    this.setContact();
  },
};
</script>
