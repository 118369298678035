<template>
  <v-layout
    align-center
    class="content-line"
    :class="{ 'content-line--button': button, disabled: disabled }"
  >
    <v-tooltip v-if="showIcon" color="appMain" top :disabled="!iconTooltip">
      <template v-slot:activator="{ on }">
        <div class="content-line__icon" v-on="on">
          <icon-base v-if="icon" :font-icon="fontIcon" :icon="icon" />
          <avatar v-if="avatar" size="small" :img="avatar" />
        </div>
      </template>
      <span>{{ iconTooltip }}</span>
    </v-tooltip>

    <slot name="customIcon" />

    <div class="content-line__text">
      <slot name="text">
        <v-tooltip
          v-if="tooltip || disabled"
          color="appMain"
          top
          max-width="300"
        >
          <template v-slot:activator="{ on }">
            <div class="content-line__text-main" v-on="on" v-html="text"></div>
          </template>
          <div v-html="tooltipText || text"></div>
        </v-tooltip>

        <div
          v-else
          class="content-line__text-main"
          :class="{ 'content-line__text--label': selectedText }"
          v-html="text"
        ></div>

        <span
          v-if="selectedText"
          class="content-line__text--selected"
          v-html="selectedText"
        ></span>
      </slot>
    </div>
    <div class="content-line__actions">
      <slot name="beforeActions" />
      <slot name="actions" />
      <v-icon v-if="disabled" color="appMain">lock</v-icon>
    </div>

    <v-progress-linear v-if="loading" indeterminate color="primary" />
  </v-layout>
</template>

<script>
import Avatar from '@/components/basic/avatar/Avatar';

export default {
  name: 'ContentLine',
  components: {
    Avatar,
  },
  props: {
    icon: String,
    fontIcon: Boolean,
    iconTooltip: String,
    text: String,
    selectedText: String,
    avatar: {
      type: [Boolean, String],
      default: false,
    },
    tooltip: Boolean,
    button: Boolean,
    loading: Boolean,
    disabled: Boolean,
    tooltipText: String,
  },
  computed: {
    showIcon() {
      return this.icon || this.avatar;
    },
  },
};
</script>

<style lang="less">
.content-line {
  height: 56px;
  padding: 10px 8px 10px 8px;
  background-color: #fff;
  position: relative;

  &__icon {
    height: 36px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }

    svg {
      max-height: 24px;
      max-width: 24px;
      flex: 1;
    }
  }

  &__text {
    flex: 1;
    font-weight: bold;
    font-size: @font-size-medium;
    color: var(--v-appMain-base);
    padding: 0 8px;
    min-width: 0;

    &--selected {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      line-height: 1.25;
    }

    &-main {
      display: block;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 20px;
      max-height: 40px;

      .highlighted {
        color: #fff !important;
        background-color: var(--v-primary-base);
        padding: 0 2px;
      }
    }

    &--label {
      font-size: 12px;
      color: #9098a4;
    }
  }

  &__actions {
    flex: 0 0 auto;
    margin-left: auto;
    display: flex;
    align-items: center;

    .v-menu:last-child {
      margin-right: -8px;
    }

    .v-menu__activator .v-icon {
      font-size: @font-size-header !important;
      color: var(--v-appMain-base) !important;
    }
  }

  &--button {
    &:hover,
    &.active {
      background-color: var(--v-lightGray-base);
    }
  }

  &.disabled {
    opacity: 0.7;
    cursor: text;
    background-color: var(--v-lightGray-base);
  }

  .v-progress-linear {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px !important;
    margin: 0 !important;
  }

  &__btn {
    margin-left: -8px !important;
    .v-btn__content {
      font-size: @font-size-medium !important;
    }
  }
}
</style>
