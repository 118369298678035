<template>
  <v-list-item @click="toggleIntercom" class="intercom-button">
    <v-list-item-icon>
      <icon-base icon="sidebar/support" />
    </v-list-item-icon>
    <v-list-item-title class="pr-2">
      Talk to Support

      <v-fade-transition>
        <notifications-count-badge :count="unreadCount" />
      </v-fade-transition>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import NotificationsCountBadge from '@/components/layouts/sidebar/helpers/NotificationsCountBadge.vue';

export default {
  name: 'IntercomButton',
  components: {
    NotificationsCountBadge,
  },
  data() {
    return {
      intercomVisible: false,
      unreadCount: 0,
    };
  },
  methods: {
    toggleIntercom() {
      if (!window.Intercom) return;
      this.intercomVisible = !this.intercomVisible;
      Intercom(this.intercomVisible ? 'show' : 'hide');
    },
    addIntercomEventsListener() {
      if (!window.Intercom) return;
      Intercom('onUnreadCountChange', (unreadCount) => {
        if (this.unreadCount === unreadCount) return;
        this.unreadCount = unreadCount;
        this.$emit('unreadCountChange', unreadCount);
      });
      Intercom('onHide', () => (this.intercomVisible = false));
    },
    checkIntercomReady() {
      let attempt = 0;
      const LIMIT = 10;

      let checker = setInterval(() => {
        if (window.Intercom || attempt > LIMIT) {
          this.addIntercomEventsListener();
          clearInterval(checker);
        }
        attempt += 1;
      }, 100);
    },
  },
  mounted() {
    this.checkIntercomReady();
  },
};
</script>

<style lang="less">
.intercom-button {
  position: relative;
}
</style>
