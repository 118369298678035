<template>
  <v-tooltip
    v-if="showTooltipComponent"
    @click.stop
    max-width="480px"
    :right="isDirection('right')"
    :left="isDirection('left')"
    :top="isDirection('top')"
    :bottom="isDirection('bottom')"
    :open-on-hover="false"
    :content-class="contentClass"
    :disabled="disabledTooltip"
    :position-y="positionY"
    :position-x="positionX"
    v-model="tooltipShow"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }" v-if="!hideActivator">
      <div v-on="on" class="smart-tooltip__trigger" @click="handleClick">
        <v-icon v-if="icon" color="primary">
          {{ icon }}
        </v-icon>
        <icon-base
          v-else
          icon="custom/help"
          :class="{ 'small-icon': isIconSmall }"
        />
        <span v-if="title">{{ title }}</span>
      </div>
    </template>

    <template v-if="contentShow">
      <div
        v-if="devMode"
        class="smart-tooltip__dev"
        v-click-outside="onClickOutside"
      >
        <p class="mb-1">
          Category: <strong>{{ this.category }}</strong>
        </p>
        <p class="mb-1">
          Slug: <strong>{{ this.slug }}</strong>
        </p>
        <p class="mb-1">
          Content:
          <strong>{{ this.tooltipContent ? 'added' : 'missing' }}</strong>
        </p>
        <p>
          Link:
          <strong>{{ this.link }}</strong>
        </p>
      </div>

      <smart-tooltip-content
        v-else
        :content="tooltipContent"
        :fullSize="fullSize"
        :link="helpDocLink"
        v-click-outside="onClickOutside"
        @toggleFullSize="toggleFullSize"
      />
    </template>
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SmartTooltipContent from '@/components/basic/smart-tooltip/SmartTooltipContent';

export default {
  name: 'SmartTooltip',
  components: {
    SmartTooltipContent,
  },
  data() {
    return {
      tooltipShow: false,
      contentShow: false,
      fullSize: false,
      postponeLinkRedirect: false,
      positionX: 0,
      positionY: 0,
    };
  },
  props: {
    category: String,
    type: String,
    title: String,
    link: Boolean,
    hideActivator: Boolean,
    icon: String,
    direction: {
      type: String,
      default: 'right', // left, top, bottom
    },
  },
  watch: {
    tooltipShow: {
      immediate: true,
      handler() {
        this.toggleContent();
      },
    },
    category: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;
        this.loadResources();
      },
    },
    loading: {
      handler(newVal) {
        if (!newVal && this.postponeLinkRedirect) {
          this.handleClick();
        }
      },
    },
  },
  computed: {
    ...mapGetters('cms', ['getSingleTooltip', 'tooltipsCategoryLoading']),
    devMode() {
      return !!this.$route?.query?.tooltips_dev;
    },
    loading() {
      return this.tooltipsCategoryLoading === this.category;
    },
    disabledTooltip() {
      return !this.tooltipContent && !this.devMode;
    },
    showTooltipComponent() {
      return !!this.tooltipContent || !!this.helpDocLink || this.devMode;
    },
    slug() {
      if ((this.type || '').startsWith('help/')) {
        return this.type.replace('help/', '');
      }
      return this.type;
    },
    helpDocLink() {
      if (!this.slug || !this.link) return '';
      return `https://conveyour.com/help/${this.slug}`;
    },
    tooltipContent() {
      if (!this.category || !this.slug) return null;
      const tooltip = this.getSingleTooltip(this.category, this.slug);
      return tooltip?.content || null;
    },
    contentClass() {
      let classStr = 'smart-tooltip';
      if (this.fullSize) {
        classStr += ' expanded';
      }

      if (this.tooltipShow) {
        classStr += ' visible';
      }

      return classStr;
    },
    isIconSmall() {
      return !!this.title || this.link;
    },
  },
  methods: {
    ...mapActions('cms', ['loadTooltips']),
    async handleClick() {
      await this.$nextTick();
      if (this.tooltipContent || this.devMode) {
        this.tooltipShow = true;
        return;
      }

      if (this.loading) {
        this.postponeLinkRedirect = true;
        return;
      }

      if (!this.helpDocLink) {
        return;
      }

      window.open(this.helpDocLink, '_blank');
    },
    async customShow(e) {
      this.positionX = e.pageX;
      this.positionY = e.pageY;

      await this.$nextTick();
      this.handleClick();
    },
    isDirection(direction) {
      return direction === this.direction;
    },
    loadResources() {
      if (!this.category) return;
      this.loadTooltips(this.category);
    },
    async onClickOutside() {
      this.tooltipShow = false;
      await this.$nextTick();
      setTimeout(() => {
        this.fullSize = false;
      }, 300);
    },
    async toggleContent() {
      if (this.tooltipShow === this.contentShow) return;

      if (!this.tooltipShow) {
        setTimeout(() => {
          this.contentShow = false;
        }, 300);
        return;
      }
      this.contentShow = true;
    },
    toggleFullSize() {
      this.fullSize = !this.fullSize;
    },
  },
  mounted() {
    this.loadResources();
  },
};
</script>

<style lang="less">
.v-tooltip__content.smart-tooltip {
  pointer-events: all;
  opacity: 0 !important;
  padding: 12px;
  background: rgba(90, 90, 90, 1);
  box-shadow: 0 0 18px 0 rgba(35, 55, 79, 0.6);

  &.visible {
    opacity: 1 !important;
  }

  &.expanded {
    top: 30px !important;
    max-height: 90vh !important;
    width: 70vw !important;
    max-width: 980px !important;
    margin: auto !important;
    left: 0 !important;
    right: 0 !important;
    bottom: initial !important;

    & > div {
      overflow: auto;
      height: 100%;
      width: 100%;
    }
  }
}

.smart-tooltip__trigger {
  cursor: pointer;
  display: inline-flex;
  padding-left: 2px;

  svg + span {
    padding-left: 4px;
  }

  svg {
    height: 22px;
    width: 22px;

    &.small-icon {
      height: 16px;
      width: 16px;
    }
  }
}
</style>
