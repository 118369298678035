import Module from '@/store/resource/common/module';
import Fields from '@/store/resource/fields';
import api from '@/factories/api';
import { prepareFields } from '@/filters/helpers';

let module = new Module(new Fields());

module.add('mutations', {
  updateFieldInBucket(state, { model, commit }) {
    let fieldsBucket = state.all.find((i) => {
      return i.id === model.bucket_id || i.fields[model.name];
    });
    fieldsBucket.fields[model.name] = model;

    commit('update', {
      model: { id: fieldsBucket.id },
      update: fieldsBucket,
    });
  },

  deleteFieldInBucket(state, { model, commit }) {
    let fieldsBucket = state.all.find((i) => i.id === model.bucket_id);
    delete fieldsBucket.fields[model.name];
    commit('update', {
      model: { id: fieldsBucket.id },
      update: fieldsBucket,
    });
  },
});

module.add('actions', {
  async validateField(state, data) {
    try {
      return await api().post('fields/validate', data);
    } catch (err) {
      throw err;
    }
  },
});

module.add('getters', {
  getSingleField(state) {
    return (field) =>
      state.all.reduce((acc, i) => {
        if (i.fields[field]) {
          return Object.assign(i.fields[field], { bucketId: i.id });
        }
        return acc;
      }, {});
  },

  preparedFields(state) {
    return state.all.reduce((acc, group) => {
      acc.push({ header: group.title, group: group.type });
      acc.push(...prepareFields(group));
      return acc;
    }, []);
  },

  additionalFields(state, getters) {
    return getters.preparedFields.filter((i) => i.group === 'rep');
  },

  attributesFields: (state, getters) => {
    return getters.preparedFields.filter((i) => i.group !== 'rep');
  },

  eventFields: (state, getters) => {
    return getters.additionalFields.filter(({ is_event }) => is_event);
  },

  defaultFields: (state) => {
    return state.all.find((i) => i.type === 'default');
  },

  getFieldSchema: (state, getters) => {
    return (fieldKey) => {
      const field = getters.getSingleField(fieldKey);
      return generateFieldSchema(field);
    };
  },

  getFieldComponentType: () => {
    return (field) => getFieldType(field);
  },
});

const generateFieldSchema = (field) => {
  if (!field.name) return;

  let schema = {
    label: getFieldLabel(field),
    title: getFieldLabel(field),
    field: field.name,
    type: getFieldType(field),
    validation: field.name === 'name' ? ['required'] : '',
    options: getFieldOptions(field.values),
    dateEditable: field.type === 'date',
  };

  // additional params for ContactListField schema
  if (schema.type === 'ContactList') {
    schema = {
      ...schema,
      bucketId: field.bucketId,
      name: field.name,
      originalType: field.type,
      filters: field.filters,
    };
  }

  return schema;
};

const getFieldLabel = (field) => {
  let label = field.label === 'name' ? field.name : field.label;
  return label.replace(/_/g, ' ');
};

const getFieldOptions = (values) => {
  if (!Array.isArray(values) || !values.length) return [];
  return values.reduce(
    (acc, i) => {
      acc.push({ text: i, value: i });
      return acc;
    },
    [{ text: ' ', value: ' ' }]
  );
};

const getFieldType = (field) => {
  const { values, type, originalType } = field || {};
  if (values?.length) {
    return 'Select';
  }

  const fieldsConverter = {
    bool: 'Checkbox',
    textarea: 'TextArea',
    list: 'ContactList',
    related_contacts: 'ContactList',
    relation: 'ContactList',
    string: 'Text',
    time: 'DateTime',
    email: 'Text',
    date: 'Date',
    int: 'Number',
    image: 'Image',
    id_number: 'Number',
  };

  return fieldsConverter[originalType || type];
};

export default module.create();
