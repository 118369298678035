import cloneDeep from 'lodash/cloneDeep';

export default {
  setLessonsLoaded: (state) => {
    state.lessonsLoaded = true;
  },
  setLoadingSingleLesson: (state, loading) => {
    state.loadingSingleLesson = loading;
  },
  setLessons: (state, lessons) => {
    state.lessons = lessons;
  },
  setLessonsView: (state, view) => {
    state.lessonsView = view;
    localStorage.setItem('lessonsView', view);
  },
  unselectLessons: (state) => {
    state.lessons.map((i) => {
      if (typeof i === 'object') {
        i.selected = false;
      }
      return i;
    });
  },
  updateLessonInList: (state, lesson) => {
    let lessons = state.lessons;
    let idx = lessons.findIndex((l) => l.id == lesson.id);

    if (idx < 0) {
      lessons.push(lesson);
    } else {
      lessons.splice(idx, 1, lesson);
    }
  },
  setLessonFolderModal: (state, modalConfig) => {
    state.lessonFolderModal = modalConfig;
  },
  setLessonsSearch: (state, search) => {
    state.lessonsSearch = search;
  },
  setLessonsSearchResult: (state, searchResult) => {
    state.lessonsSearchResult = searchResult;
  },
  setLessonsSearchLoading: (state, loading) => {
    state.lessonsSearchLoading = loading;
  },

  clearSearchLessons: (state) => {
    state.lessonsSearch = '';
    state.lessonsSearchResult = [];
    state.lessonsSearchLoading = false;
  },

  setLessonMeta(state, data) {
    state.lessonMeta = data;
  },

  setLessonsNames: (state, names) => {
    state.lessonsNames = names.map((l) => {
      return {
        text: l.name,
        value: l.id,
      };
    });
  },

  setLessonsTrackerNames: (state, names) => {
    state.lessonsTrackerNames = names;
  },

  setLessonsNamesLoaded: (state, loading) => {
    state.lessonsNamesLoaded = loading;
  },

  setLessonsFilter: (state, filter) => {
    state.lessonsFilter = filter;
  },

  setItemMetrics(state, { itemUuid, metrics }) {
    let itemMetrics = cloneDeep(state.itemMetrics);
    itemMetrics[itemUuid] = metrics;
    state.itemMetrics = itemMetrics;
  },

  setLessonMetrics(state, { lessonId, metrics }) {
    let lessonMetrics = cloneDeep(state.lessonMetrics);
    lessonMetrics[lessonId] = metrics;
    state.lessonMetrics = lessonMetrics;
  },

  setLessonMetricsFilter(state, filter) {
    state.lessonMetricsFilter = filter;
  },
};
