<template>
  <v-dialog
    v-if="isTeamsEnabled"
    content-class="fixed-height-md"
    v-model="teamPickerShow"
    max-width="680px"
  >
    <modal-card
      title="Select Team"
      fixed
      padding
      @close="teamPickerShow = false"
    >
      <h4 class="appMain--text mb-2" v-if="sharing">
        Share {{ sharing.item.name }} to teams:
      </h4>
      <search-block
        v-model="selectedTeams"
        placeholder="Select teams"
        :options="teamsFormatted"
        :expandable="false"
        showSelected
        :multiple="!!sharing"
      />

      <template v-slot:actions>
        <v-btn v-if="sharing" color="appMain" @click="shareToTeams">
          Share
        </v-btn>
        <v-btn v-else color="appMain" @click="setTeam"> Save </v-btn>
      </template>
    </modal-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EventBus from '@/factories/eventBus';
import SearchBlock from '@/components/basic/search-block/SearchBlock';
import isEqual from 'lodash/isEqual';

export default {
  name: 'TeamPicker',
  components: {
    SearchBlock,
  },
  data() {
    return {
      initialTeamId: '',
      selectedTeams: [],
      teamPickerShow: false,
      sharing: null,
      resourcePath: {
        campaigns: 'campaigns',
        folders: 'lessons/folders',
      },
    };
  },
  computed: {
    ...mapGetters('users', ['isAdmin', 'currentUserLoaded', 'userTeam']),
    ...mapGetters('teams', ['all', 'byId', 'currentTeam', 'loaded']),
    teamsFormatted() {
      return this.all
        .map((i) => {
          return {
            label: i.label || '',
            value: i.id,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    isTeamsEnabled() {
      return this.isFeatureEnabled('teams');
    },
  },
  watch: {
    loaded: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val) return;
        this.checkCurrentTeam();
      },
    },
    currentUserLoaded: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.checkTeams();
      },
    },
  },
  methods: {
    ...mapActions('campaigns', ['updateCampaign']),
    ...mapActions('users', ['updateUserTeam']),
    ...mapActions('teams', ['load', 'updateResourceTeams', 'safeReload']),

    checkTeams() {
      if (this.loaded) return;
      this.load();
    },

    async checkCurrentTeam() {
      //if teams get loaded after user is loaded
      //but if ?team_id=:id was passed to the page
      //mounted() will receive setTeams(teamId) before
      //teams return
      if (this.initialTeamId) {
        if (!this.currentTeam || this.currentTeam.id !== this.initialTeamId) {
          return this.setInitialTeam();
        }
      }

      // check if user team exists
      if (this.userTeam) {
        const serverTeam = this.byId(this.userTeam);

        // if team doesn't exist
        if (!serverTeam) {
          // clear storage and refresh page
          if (this.isAdmin) {
            return this.setTeam();
          }

          // clear storage
          await this.updateUserTeam(null);
        }
      }

      // if team is not set in profile, but exists in storage
      if (!this.userTeam && this.currentTeam) {
        this.updateUserTeam(this.currentTeam?.id);
      }

      // set default group for guest user
      if (!this.isAdmin && !this.currentTeam) {
        this.selectedTeams = this.all[0].id;
        this.setTeam();
      }
    },

    shareToTeams() {
      let data = this.prepareData();

      if (data.add_teams.length || data.remove_teams.length) {
        this.updateResourceTeams({
          data,
          path: this.resourcePath[this.sharing.type],
          resourceId: this.sharing.item.id,
        }).then(() => {
          this.sharing.item.teams = data.full;
          this.closePicker();
        });
      }
    },

    async setInitialTeam() {
      await this.updateUserTeam(this.initialTeamId);

      let query = this.$route.query;
      if (query.team_id) {
        delete query.team_id;
      }

      const { origin, pathname } = window.location;

      let href = `${origin}${pathname}`;
      query = new URLSearchParams(query).toString();
      if (query) {
        href += `?${query}`;
      }
      window.location.href = href;
    },

    async setTeam() {
      const team = this.byId(this.selectedTeams) || null;
      await this.updateUserTeam(team?.id);
      this.safeReload();
    },

    closePicker() {
      this.teamPickerShow = false;
      this.selectedTeams = [];
      this.sharing = null;
    },

    prepareData() {
      let addedTeams = [];
      let removedTeams = [];
      let currentTeams = this.sharing.item.teams || [];

      if (currentTeams.length) {
        removedTeams = currentTeams.filter(
          (i) => !this.selectedTeams.includes(i)
        );
        addedTeams = this.selectedTeams.filter(
          (i) => !currentTeams.includes(i)
        );
      } else {
        addedTeams = this.selectedTeams;
      }

      return {
        add_teams: addedTeams,
        remove_teams: removedTeams,
        full: this.selectedTeams,
      };
    },
    checkInitialTeamPassed() {
      const teamId = this.$route.query?.team_id;
      if (teamId) {
        this.initialTeamId = teamId;
      }
    },
  },
  mounted() {
    this.checkInitialTeamPassed();

    EventBus.$on('showTeamPicker', ({ sharing }) => {
      if (sharing?.item) {
        this.sharing = sharing;
        this.selectedTeams = sharing.item.teams || [];
      } else {
        this.selectedTeams = this.currentTeam?.id;
      }
      this.teamPickerShow = true;
    });
  },
};
</script>
