import Module from '@/store/resource/common/module';
import Activity from '@/store/resource/activity';

let module = new Module(new Activity());

module.add('getters', {
  count(state) {
    return state.count;
  },
  page(state) {
    return state.page;
  },
});

module.add('mutations', {
  setPagination(state, data) {
    state.page = data.page;
    state.pages = data.pages;
    state.count = data.count * 1;
  },
});

export default module.create();
