import api from '@/factories/api';

import { configureTrackJS } from '@/lib/trackjs';
import { initAbly } from '@/lib/ably';
import { identify } from '@/lib/analytics';
import { getDeviceToken } from '@/firebase';
import { isNotificationsEnabled } from '@/lib/notification';
import { askNotificationsPermission } from '@/lib/notification';

export default {
  async loadUsers({ commit, getters }, schemaFull = false) {
    if (getters.usersLoaded) {
      if (!schemaFull || getters.users.every(({ schema }) => schema === 'full'))
        return;
    }

    commit(`setUsersLoaded`, false);

    try {
      const path = 'users' + (schemaFull ? '' : '/usernames');
      const teamFilterOff = getters.isAdmin;

      const res = await api({ teamFilterOff }).get(path);
      let { users = [], self = {} } = res || {};

      if (self && !users.some(({ id }) => id?.value === self?.id?.value)) {
        users.push(self);
      }

      if (schemaFull) {
        users = convertUsersArr(users);
      }

      commit('setUsers', users);
      return true;
    } catch (err) {
      throw err;
    } finally {
      commit(`setUsersLoaded`, true);
    }
  },

  async loadSingleUser({ commit }, userId) {
    commit('setUserLoading', true);
    try {
      const res = await api().get('users/' + userId);
      commit('setUser', convertUserDataToPlainObject(res));
      return res;
    } catch (err) {
      throw err;
    } finally {
      commit('setUserLoading', false);
    }
  },

  async updateUser({ commit }, { data, permissions }) {
    let id = data.id;
    if (!id) return false;

    commit('setUserLoading', true);
    try {
      let userData = await api().put('users/' + id, prepareUserData(data));

      if (Array.isArray(userData)) {
        userData = Object.keys(data).reduce((acc, i) => {
          acc[i] = { value: data[i] };
          return acc;
        }, {});
      }

      if (permissions && !userData.permissions) {
        userData.permissions = preparePermissions(data, permissions);
      }

      commit('setUser', convertUserDataToPlainObject(userData));
    } catch (err) {
      throw err;
    } finally {
      commit('setUserLoading', false);
    }
  },

  async updateUserTeam({ getters }, teamId) {
    try {
      const userId = getters.currentUser?.id;

      await api().put('users/' + userId, {
        selected_team: teamId ?? '',
      });

      localStorage.removeItem('teams.current');
    } catch (err) {
      console.log('failed to set user team', err);
    }
  },

  async changeUserCredentials({}, { data, type }) {
    try {
      return await api().put(`users/${type}`, data);
    } catch (err) {
      throw err;
    }
  },

  async reinviteUser({}, userId) {
    try {
      return await api().post(`users/reinvite/${userId}`);
    } catch (err) {
      throw err;
    }
  },

  async createUser({ commit, dispatch, getters }, user) {
    commit('setUserLoading', true);
    try {
      const res = await api().post('users', user);
      const userId = res?.new_user_id || '';

      if (!userId) return;

      // load fresh user
      dispatch('loadSingleUser', userId);

      // update team storage
      const teams = user?.teams || [];
      if (teams.length) {
        dispatch('teams/addNewUserToTeam', { userId, teams }, { root: true });
      }
    } finally {
      commit('setUserLoading', false);
    }
  },

  async removeUser({ commit, dispatch, getters }, userId) {
    try {
      const res = await api().delete(`users/${userId}`);

      dispatch('teams/updateUserTeams', { userId, teams: [] }, { root: true });

      commit('setUser', {
        id: userId,
        status: '0',
        teams: [],
      });
      return res;
    } catch (err) {
      throw err;
    }
  },

  async restoreUser({ commit }, userId) {
    try {
      await api().post(`users/restore/${userId}`);
      commit('setUser', {
        id: userId,
        status: '1',
      });
    } catch (err) {
      throw err;
    }
  },

  async loadPermissions({ commit, state }) {
    if (state.allPermissions.length) return;

    try {
      const permissions = await api().get('users/permissions');
      commit('setPermissions', permissions);
    } catch (err) {
      throw err;
    }
  },

  async loadCurrentUser({ commit, getters, dispatch }) {
    if (getters.currentUserLoaded) return;

    try {
      const userInfo = await api({}).get('users/current');
      if (!userInfo || Array.isArray(userInfo)) return { failed: true };

      commit('setCurrentUser', userInfo);
      configureTrackJS(userInfo.id);
      askNotificationsPermission(() => dispatch('initFirebaseMessaging'));
      trackUserInfo(userInfo);
    } catch (error) {
      throw error;
    } finally {
      commit('setCurrentUserLoaded', true);
    }
  },

  async loadUsersTimezones({ commit, getters }) {
    if (getters.usersTimezones) return;

    try {
      const usersTimezones = await api().get('users/timezones');
      commit('setUsersTimezones', usersTimezones);
    } catch (error) {
      throw error;
    }
  },

  async searchTwilioNumbers({}, params) {
    try {
      const numbers = await api().get('twilio/search_phones', { params });
      return numbers || [];
    } catch (error) {
      throw error;
    }
  },

  async purchaseTwilioNumber({}, data) {
    const teamFilterOff = true;
    try {
      const res = await api({ teamFilterOff }).post(
        'twilio/purchase_phone',
        data
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  checkUserActivity({ commit }, { config, data }) {
    if (!config || !data) return;
    const trackRoutes = ['lesson', 'campaign', 'trigger'];
    const checkMethods = ['put', 'post', 'delete'];
    const url = (config.url || '').replace('/api/', '');
    const type = trackRoutes.find((i) => url.startsWith(i));

    if (!type || !checkMethods.some((i) => i === config.method)) return;

    setTimeout(() => {
      // clear stored suggestions
      commit(
        'setNamesSuggestions',
        { type: [`${type}s`], names: null },
        { root: true }
      );

      const item = Object.assign(
        JSON.parse(config.data || '{}'),
        data && data.data ? data.data : {}
      );
      const getType = () => {
        if (type === 'trigger')
          return item.type === 'lesson' ? 'lesson' : 'automation';
        return type;
      };

      if (config.method === 'delete') {
        commit('removeUserActivity', getItemId(config.url));
        return;
      }

      commit('addUserActivity', {
        route: window.location.pathname,
        data: {
          id: item.id,
          name: item.name,
          uuid: item.uuid,
        },
        type: getType(),
        timestamp: new Date().getTime(),
      });
    }, 500);
  },

  setDeviceToken(_, device_token) {
    if (!device_token) return;

    try {
      api().post('users/device_token', { device_token });
    } catch (err) {
      console.log('set device token error', err);
    }
  },

  async removeDeviceToken(_, device_token) {
    if (!device_token) return;

    try {
      await api().delete('users/device_token', {
        data: { device_token },
      });
    } catch (err) {
      console.log('set device token error', err);
    }
  },

  async getCurrentDeviceTokens({ dispatch }) {
    try {
      let tokens = (await api().get('users/device_token')) || [];

      // convert tokens to array
      if (typeof tokens === 'object' && !Array.isArray(tokens)) {
        tokens = Object.values(tokens);
      }

      // fcm docs
      // Your app should resubscribe to topics once per month and/or whenever the registration token changes.
      // If an app instance is idle for 2 months (or your own staleness window) you should unsubscribe it.
      let validTokens = [];

      tokens.forEach((t) => {
        const monthLaterTimestamp =
          28 * 24 * 60 * 60 * 1000 + t.timestamp * 1000;
        if (monthLaterTimestamp > new Date().getTime()) {
          validTokens.push(t);
        } else {
          dispatch('removeDeviceToken', t.token);
        }
      });

      return validTokens;
    } catch (err) {
      console.log('get device tokens error', err);
    }
  },

  async initFirebaseMessaging({ dispatch, getters }) {
    if (!isNotificationsEnabled() || !getters.isUserLoggedIn) return;

    const device_token = await getDeviceToken();
    if (!device_token) {
      console.log('unable to get device token');
      return;
    }

    const currentTokens = await dispatch('getCurrentDeviceTokens');
    const tokenExists = currentTokens.some(
      ({ token }) => token === device_token
    );

    if (!tokenExists) {
      dispatch('setDeviceToken', device_token);
    } else {
      console.log('token already exists');
    }
  },
};

// for some reason GET users request returns Boolean value for permissions fields
// but for PUT we need to send them as '1'/'0'
const prepareUserData = (data) => {
  const permissionsFields = [
    'add_contacts',
    'delete_contacts',
    'import_contacts',
  ];
  return Object.keys(data).reduce((acc, i) => {
    acc[i] = permissionsFields.includes(i) ? (data[i] ? '1' : '0') : data[i];
    return acc;
  }, {});
};

const getItemId = (url) => {
  const urlArr = url.split('/');
  return urlArr[urlArr.length - 1];
};

const preparePermissions = (data, permissions) => {
  return {
    label: 'Permissions',
    value: Object.keys(data).reduce((acc, i) => {
      if (i === 'id') return acc;

      if (data[i]) {
        acc.push(i);
      } else {
        acc = acc.filter((p) => p !== i);
      }
      return acc;
    }, permissions),
  };
};

const convertUsersArr = (arr) => {
  return arr.map((i) => convertUserDataToPlainObject(i));
};

const convertUserDataToPlainObject = (user) => {
  let obj = {
    schema: 'full',
  };

  for (let key in user) {
    obj[key] = user[key]?.value;
  }

  obj.name = `${obj.first_name} ${obj.last_name}`;
  obj.initials =
    obj?.intials ||
    obj.name
      .split(' ')
      .map((i) => {
        return i.slice(0, 1);
      })
      .join('');

  return obj;
};

const updateIntercomInfo = (info) => {
  if (!window.Intercom || !info) return;

  const {
    first_name = '',
    last_name = '',
    id,
    email,
    mobile,
    photo,
    intercom_user_hash,
  } = info;

  Intercom('boot', {
    user_id: id,
    user_hash: intercom_user_hash,
    name: `${first_name} ${last_name}`,
    email,
    phone: mobile,
    avatar: {
      type: 'avatar',
      image_url: photo,
    },
  });
};

const trackUserInfo = (userInfo) => {
  identify(userInfo);
  initAbly(userInfo);
  updateIntercomInfo(userInfo);
};
