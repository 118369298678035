<template>
  <div
    class="search-block"
    :class="{ 'search-block--arrow-nav': arrowsNavigation }"
  >
    <div class="search-input__wrap">
      <v-text-field
        class="search-input dark-input"
        prepend-icon="search"
        :placeholder="placeholder"
        :hint="hint"
        :rules="checkRules()"
        :value="search"
        :loading="loading"
        @input="setSearch"
        @keyup.enter="addNewOption"
        clearable
        :autofocus="autofocus"
        clear-icon="cancel"
      />
      <v-btn
        v-if="showAddButton"
        color="appMain"
        class="ml-2"
        @click="addNewOption"
      >
        Add
      </v-btn>
    </div>

    <div
      v-if="value && selectedOption"
      class="list-field__item active selected light--text mt-1 mb-1"
    >
      <span class="list-field__item-text">
        Selected: {{ selectedOption.label }}
      </span>
      <slot name="selectedBadge" />
    </div>

    <template v-if="noResultsFound">
      <slot name="notFound" v-bind:search="search"></slot>
    </template>

    <transition name="slide">
      <list-field
        v-if="options.length"
        :value="value"
        :items="filteredOptions"
        :showIcons="showIcons"
        :multiple="multiple"
        :expandable="expandable"
        :showSelected="showSelected"
        :disableUnselect="disableUnselect"
        :arrowsNavigation="arrowsNavigation"
        :selected-first="selectedFirst"
        :view-route="viewRoute"
        :visibleCount="visibleCount"
        @input="chooseOption"
      />
    </transition>

    <slot name="action" />
  </div>
</template>

<script>
import ListField from '../list-field/ListField';
import { prepareTag } from '@/filters/helpers';

export default {
  name: 'SearchBlock',
  components: {
    ListField,
  },
  data() {
    return {
      search: '',
    };
  },
  props: {
    value: {
      type: [String, Number, Array],
    },
    options: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
    viewRoute: Object,
    expandable: {
      type: Boolean,
      default: true,
    },
    hint: String,
    placeholder: String,
    fieldName: String,
    autofocus: Boolean,
    selectedFirst: Boolean,
    arrowsNavigation: Boolean,
    multiple: Boolean,
    showIcons: Boolean,
    showSelected: Boolean,
    disableUnselect: Boolean,
    addOption: [Boolean, Function],
    visibleCount: Number,
    loading: Boolean,
  },
  computed: {
    filteredOptions() {
      return this.options.filter((i) => {
        const { label, value } = i || {};
        return this.compareStrings(label) || this.compareStrings(value);
      });
    },

    selectedOption() {
      return this.findSelected(this.options, this.value);
    },

    showAddButton() {
      return this.addOption && this.noResultsFound;
    },

    noResultsFound() {
      const search = this.search.trim();
      return search !== '' && !this.filteredOptions.length;
    },
  },
  methods: {
    checkRules() {
      if (!this.rules || !this.rules.length) return [];
      return this.rules.map((rule) => {
        return rule(this.value);
      });
    },

    findSelected(arr, val) {
      return arr.reduce((acc, item) => {
        if (acc) return acc;
        if (item.value === val) return item;
        if (item?.items?.length) return this.findSelected(item.items, val);
      }, null);
    },

    chooseOption(val) {
      this.$emit('input', val);
    },

    async addNewOption() {
      if (!this.showAddButton) return;
      let option = this.search.trim();

      if (typeof this.addOption === 'function') {
        this.addOption(option);
        await this.$nextTick();
      } else {
        if (this.fieldName === 'tags') {
          option = prepareTag(option);
        }
        this.value.push(option);
      }

      this.setSearch('');
    },

    compareStrings(fullString = '') {
      return (fullString + '')
        .toLowerCase()
        .includes((this.search || '').toLowerCase());
    },

    setSearch(val) {
      this.search = val;
      this.$emit('updateSearch', val);
    },
  },
};
</script>

<style lang="less">
.search-block {
  padding: 16px;
  border-radius: @base-radius;
  overflow: hidden;
  background-color: #fff;
  box-shadow: @content-shadow;

  & > .v-btn.v-btn--text {
    width: 100%;
    padding: 0 14px !important;
    margin-left: 26px;

    .v-btn__content {
      justify-content: left;
      color: var(--v-primary-base);
    }
  }

  .v-text-field__details {
    display: block !important;
    min-height: 12px;

    .v-messages {
      min-height: 12px;
    }

    .v-messages__message {
      margin-top: 6px;
    }
  }

  div.search-input.v-input {
    .v-input__slot {
      padding: 4px 15px 4px 42px;
      box-shadow: none;
    }

    .v-input__prepend-outer {
      top: 5px;
      left: 8px;

      .v-icon {
        color: var(--v-appMain-base) !important;
      }
    }

    .v-input__append-inner {
      margin-top: 4px;
      margin-right: -5px;

      .v-icon {
        color: var(--v-appMain-base) !important;
      }
    }
  }

  .list-field {
    margin-left: 27px;
    padding: 3px 0 0;
  }

  .list-field__item {
    font-size: @font-size-base;
    padding: 8px 15px;

    &.active {
      background: var(--v-appMain-base);

      .v-icon,
      .list-field__item-text {
        color: var(--v-mainInvert-base) !important;
      }
    }
  }

  &.search-block--arrow-nav {
    flex: 1;
    display: flex;
    flex-direction: column;

    .list-field {
      overflow: auto;
      flex: 1;
      position: relative;
    }
  }
}

.search-list {
  &__item {
    background-color: #fff;
  }
}

.search-input__wrap {
  display: flex;

  .v-btn {
    height: 40px !important;
    margin-left: 4px;
  }
}
</style>
