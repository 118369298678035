import clone from 'lodash/clone';
import extend from 'lodash/extend';
import isObject from 'lodash/isObject';
import _ from 'lodash';

/**
 * global config for all operators, you can define any fake operator
 * here, it will be converted to valid operator when saving
 *
 * please do not get config from the var, use getConfig instead
 *
 * @type Object
 */
const CONFIG = {
  $eq: {
    label: 'is',
    list_label: 'has',
    event: {
      tracking_label: 'has',
    },
  },

  $ne: {
    label: 'is not',
    list_label: 'does not have',
    event: {
      tracking_label: 'does not have',
    },
  },

  $exists: {
    label: 'has any value',
    operator: '$exists',
    value: 'true',
    event: {
      tracking_label: 'any',
    },
  },

  '^$exists': {
    label: 'is empty',
    operator: '$exists',
    value: 'false',
  },

  '?': {
    label: 'contains',
    list_label: 'contains (advanced)',
    event: {
      tracking_label: 'contained',
    },
  },

  '^?': {
    label: 'does not contain',
    event: {
      tracking_label: 'did not contain',
    },
  },

  '^': {
    label: 'starts with',
    event: {
      tracking_label: 'started with',
    },
  },

  '^^': {
    label: 'does not start with',
    event: {
      tracking_label: "didn't start with",
    },
  },

  $: {
    label: 'ends with',
    event: {
      tracking_label: 'ended with',
    },
  },

  '^$': {
    label: 'does not end with',
    event: {
      tracking_label: "didn't end with",
    },
  },

  $gt: {
    label: 'greater than',
    type: 'number',
  },

  $gte: {
    label: 'greater than or equal to',
    type: 'number',
  },

  $lt: {
    label: 'less than',
    type: 'number',
  },

  $lte: {
    label: 'less than or equal to',
    type: 'number',
  },

  $after: {
    label: 'after',
    operator: '$gt',
    type: 'date',
  },

  $before: {
    label: 'before',
    operator: '$lt',
    type: 'date',
  },

  $on: {
    label: 'on',
    operator: '$eq',
    type: 'date',
  },

  $non: {
    label: 'not on',
    operator: '$ne',
    type: 'date',
  },

  $more: {
    label: 'more than ... days ago',
    operator: '$lt',
    relative: true,
    unit: 'days',
    type: 'number',
  },

  $moreExpression: {
    label: 'greater than',
    operator: '$gt',
    relative: true,
    expression: true,
    type: 'string',
  },

  $less: {
    label: 'less than ... days ago',
    operator: '$gt',
    relative: true,
    unit: 'days',
    type: 'number',
  },

  $lessExpression: {
    label: 'less than',
    operator: '$lt',
    relative: true,
    expression: true,
    type: 'string',
  },

  $exactly: {
    label: 'exactly ... days ago',
    operator: '$eq',
    relative: true,
    type: 'number',
  },

  $exactlyExpression: {
    label: 'matches',
    operator: '$eq',
    relative: true,
    expression: true,
    type: 'string',
  },

  //for lists
  $in: {
    label: 'has at least one of',
    string_label: 'is one of',
    list: true,
  },

  $all: {
    label: 'has all of',
    list: true,
  },

  $nin: {
    label: 'has none of',
    string_label: 'is none of',
    list: true,
  },

  $descendantOf: {
    label: 'descendant of',
    string_label: 'descendant of',
  },
};

/**
 * group data for all field types
 *
 * field type can include single group or multi groups with label
 *
 * @type Object
 */
const GROUP = {
  list: ['$in', '$all', '$nin', '$eq', '$ne', '?'],

  related_contacts: ['$in', '$all', '$nin', '$eq', '$ne', '$descendantOf'],

  relation: ['$in', '$all', '$nin', '$eq', '$ne'],

  string: [
    '$eq',
    '$ne',
    '?',
    '^?',
    '^',
    '^^',
    '$',
    '^$',
    '$exists',
    '^$exists',
    '$in',
    '$nin',
  ],

  //specific fields
  trigger: ['$eq', '$ne', '$exists', '^$exists'],
  lesson: ['$eq', '$ne', '$exists', '^$exists'],
  campaign: ['$eq', '$ne', '$exists', '^$exists'],
  item: ['$eq', '$ne', '$exists', '^$exists'],
  event: ['$eq', '$ne', '$exists', '^$exists'],

  int: ['$eq', '$ne', '$gt', '$gte', '$lt', '$lte', '$exists', '^$exists'],

  bool: ['$eq', '$ne', '$exists', '^$exists'],

  date: {
    Absolute: ['$after', '$on', '$non', '$before', '$exists', '^$exists'],
    Relative: ['$more', '$exactly', '$less'],
  },

  time: {
    Absolute: ['$after', '$on', '$non', '$before', '$exists', '^$exists'],
    Relative: ['$more', '$exactly', '$less'],
    'Expression (Advanced)': [
      '$moreExpression',
      '$exactlyExpression',
      '$lessExpression',
    ],
  },
};

export const FIXED_FIELDS = [
  {
    fixed: true,
    text: 'Event',
    value: 'event',
    type: 'string',
    field: 'event',
  },
  {
    fixed: true,
    text: 'Count',
    value: 'count',
    type: 'int',
    field: 'count',
  },
  {
    fixed: true,
    text: 'Occurred',
    value: 'date',
    type: 'date',
    field: 'date',
  },
  {
    fixed: true,
    text: 'Points Total',
    value: 'score',
    type: 'int',
    field: 'score',
  },
];

export const activities = [
  {
    value: 'joined_campaign',
    text: 'Joined Campaign',
    activity: true,
  },
  {
    value: 'not_joined_campaign',
    text: 'Has Not Joined Campaign',
    activity: true,
  },
  {
    value: 'campaign_progress',
    text: 'Campaign Progress',
    activity: true,
  },
  {
    value: 'answered_question',
    text: 'Answered Question',
    activity: true,
  },
  {
    value: 'recorded_stats',
    alternative_value: 'answered_question',
    text: 'Answered Question - Recorded Stats',
    activity: true,
  },
  {
    value: 'watched_video',
    text: 'Watched Video',
    activity: true,
  },
  {
    value: 'shared',
    text: 'Shared',
    activity: true,
  },
  {
    value: 'completed_lesson',
    text: 'Completed Lesson',
    activity: true,
  },
  {
    value: 'not_completed_lesson',
    text: 'Has Not Completed Lesson',
    activity: true,
  },
  {
    value: 'completed_lesson_item',
    text: 'Completed Lesson Item',
    activity: true,
  },
  {
    value: 'started_lesson',
    text: 'Started Lesson',
    activity: true,
  },
  {
    value: 'points_scored',
    text: 'Points Scored',
    activity: true,
  },
  {
    value: 'recorded_stats',
    text: 'Recorded Stats',
    activity: true,
  },
  {
    value: 'uploaded_video',
    text: 'Uploaded Video',
    activity: true,
  },
];

const getDefaultEventScheme = (event) => {
  return [
    {
      event: { $eq: event },
    },
    {
      date: { $less: '30' },
    },
  ];
};

// const getCustomEventScheme = (config) => {
//   const { evt, date, field = '396.lesson', fieldOperator = '$eq' } = config;
//   return [
//     {
//       event: { $eq: evt },
//     },
//     {
//       date: date || { $exists: true },
//     },
//     {
//       [field]: { [fieldOperator]: '' },
//     },
//   ];
// };

export const sectionDefinition = {
  joined_campaign: [
    {
      event: { $eq: 'joined_campaign' },
    },
    {
      date: { $exists: true },
    },
    {
      ['395.campaign']: { $eq: '' },
    },
  ],
  not_joined_campaign: [
    {
      event: { $eq: 'joined_campaign' },
    },
    {
      date: { $exists: true },
    },
    {
      ['395.campaign']: { $ne: '' },
    },
  ],
  campaign_progress: [
    {
      event: { $eq: 'campaign_progress' },
    },
    {
      ['395.campaign']: { $eq: '' },
    },
    {
      ['396.progress']: { $gt: '90' },
    },
    {
      ['396.lessons_total']: { $gte: '1' },
    },
    {
      date: { $less: '30' },
    },
  ],
  answered_question: [
    {
      event: { $eq: 'answered_question' },
    },
    {
      date: { $exists: true },
    },
    {
      ['396.lesson']: { $eq: '' },
    },
  ],
  uploaded_video: [
    {
      event: { $eq: 'uploaded_video' },
    },
    {
      date: { $exists: true },
    },
    {
      ['396.lesson']: { $eq: '' },
    },
  ],
  watched_video: [
    {
      event: { $eq: 'watched_video' },
    },
    {
      date: { $exists: true },
    },
    {
      ['396.lesson']: { $eq: '' },
    },
  ],
  shared: [
    {
      event: { $eq: 'shared' },
    },
    {
      date: { $exists: true },
    },
    {
      ['396.lesson']: { $eq: '' },
    },
  ],
  completed_lesson: [
    {
      event: { $eq: 'completed_lesson' },
    },
    {
      date: { $less: '30' },
    },
    {
      ['396.lesson']: { $eq: '' },
    },
  ],
  not_completed_lesson: [
    {
      event: { $eq: 'completed_lesson' },
    },
    {
      date: { $less: '30' },
    },
    {
      ['396.lesson']: { $ne: '' },
    },
  ],
  completed_lesson_item: [
    {
      event: { $eq: 'completed_lesson_item' },
    },
    {
      date: { $exists: true },
    },
    {
      ['396.lesson']: { $eq: '' },
    },
  ],
  started_lesson: [
    {
      event: { $eq: 'started_lesson' },
    },
    {
      date: { $less: '30' },
    },
    {
      ['396.lesson']: { $eq: '' },
    },
  ],
  points_scored: [
    {
      event: { $eq: 'points_scored' },
    },
    {
      score: { $gte: '1000' },
    },
    {
      count: { $exists: true },
    },
    {
      date: { $less: '30' },
    },
  ],
  recorded_stats: [
    {
      event: { $eq: 'recorded_stats' },
    },
    {
      count: { $gte: '1' },
    },
    {
      date: { $less: '30' },
    },
  ],
};

export const eventsWithOptionalFields = [
  'completed_lesson',
  'started_lesson',
  'recorded_stats',
  'points_scored',
  'answered_question',
  'completed_lesson_item',
  'uploaded_video',
  'shared',
  'watched_video',
];

export const getSection = (type) => {
  return sectionDefinition[type] || getDefaultEventScheme(type);
};

export const searchableFields = [
  'string',
  'list',
  'email',
  'textarea',
  'answer',
  'related_contacts',
  'relation',
];

export const namesSuggestionsTypes = {
  campaign: 'campaigns',
  campaigns: 'campaigns',
  lesson: 'lessons',
  trigger: 'triggers',
};

/**
 * get group data for a field type
 *
 * @param {String} type
 * @param {Boolean} merge some type may include multi groups,
 *  it could merge them and just return a single array
 * @returns {unresolved}
 */
const getGroup = (type, merge) => {
  var g = clone(GROUP[type] || GROUP.string);
  if (Array.isArray(g) || !merge) {
    return g;
  }
};

/**
 * get config for an operator
 *
 * @param {String} key
 * @returns {Object}
 */
const getConfig = (key, field) => {
  var c;

  c = CONFIG[key] || {};
  c = isObject(c) ? c : { label: c };
  c.key = key;

  c.operator = c.operator || c.key;

  c = clone(c);

  //this allows us to to sub-class configuration by field
  //so like tracking field 'event' can have a tiny bit of vernacular change
  if (field && c[field]) {
    extend(c, c[field]);
    delete c[field];
  }

  return clone(c);
};

export const makeOperatorOptions = (field, isTracking = false, operator) => {
  let data, group;

  data = {};
  const type = field.type;
  const _field = field.field;

  group = GROUP[_field] ? getGroup(_field) : getGroup(type);

  // attach current operator to options
  if (Array.isArray(group) && operator && !group.some((i) => i === operator)) {
    group.unshift(operator);
  }

  var filter = function (options) {
    options = _.map(options, function (o) {
      let config = getConfig(o, _field);
      config.value = config.key;
      config.operator = config.operator || config.key;
      config.selected = config.key === operator ? 'selected' : '';

      if (isTracking && config.tracking_label) {
        config.label = config.tracking_label;
      }
      //let's us set different labels depending
      //the type
      if (config[type + '_label']) {
        config.label = config[type + '_label'];
      }
      return config;
    });

    // if doing tracking queries
    // don't allow list creation type operators
    // if( self.parent.tracking ){
    //   options = _.filter(options, function(o){
    //     return !o.list;
    //   });
    // }

    return options;
  };

  if (Array.isArray(group)) {
    return filter(group);
  } else {
    let convertedGroup = [];
    for (let key in group) {
      convertedGroup.push({ header: key });
      convertedGroup.push(...filter(group[key]));
    }
    return convertedGroup;
  }
};
