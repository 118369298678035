import cloneDeep from 'lodash/cloneDeep';
import api from '@/factories/api';
import { fsIdentify } from '@/lib/fullstory';
import { initAblyInPortal } from '@/lib/ably';
import localStore from 'store';
import router from '@/router';

export default {
  async loginPortal({ commit, getters }, data) {
    if (getters.profileLoading) return;

    commit('setProfileLoading', true);
    try {
      const res = await api().post('cg/auth', data);
      if (!res?.data?.access_token) return res;

      const profile = res.data;
      commit('setProfilePid', profile.pid);
      commit('setProfile', profile);

      localStore.set('auth', {
        //using id not pid so that if
        //we ever move to having users
        //use tokens we can use user_id and contact.pid
        //interchangeably.
        //we store the id with the auth so that if we are navigating
        //between portal URL's as a tester, we don't send the same
        //token for each portal per domain!
        id: getters.profilePid,
        token: profile.access_token,
      });

      initAblyInPortal(profile);

      fsIdentify({
        id: getters.profilePid,
        data: profile,
      });

      return profile;
    } catch (error) {
      localStore.set('auth', {});
      throw error;
    } finally {
      commit('setProfileLoading', false);
    }
  },

  async loginScormLearner({ commit, getters, dispatch }, params) {
    const { learnerId, learnerName, courseId, metadata } = params;

    commit('setProfileLoading', true);
    commit('setScormParameters', { courseId, learnerId });

    try {
      // login
      const res = await api({ baseURL: '/' }).post('plugin/scorm/contacts', {
        learner_id: learnerId,
        name: learnerName,
        metadata,
      });

      const { contact_pid, token } = res || {};
      if (!contact_pid || !token) return;

      localStore.set('auth', { id: contact_pid, token });

      // load profile info
      await dispatch('loadProfile');

      // join scorm course
      await dispatch('joinScormCourse');
    } catch (error) {
      throw error;
    } finally {
      commit('setProfileLoading', false);
    }
  },

  async joinScormCourse({ state }) {
    try {
      const { learnerId, courseId } = state.scormParameters;

      await api({ baseURL: '/' }).post('plugin/scorm/contacts/campaign', {
        learner_id: learnerId,
        campaign_id: courseId,
      });

      router.push({
        name: 'portal-course',
        params: { id: courseId },
      });
    } catch (error) {
      console.log('failed to join scorm course', error);
    }
  },

  async loadProfile({ commit, getters }) {
    commit('setProfileLoading', true);
    try {
      const profile = await api().get('cg/info?schema=full');
      commit('setProfile', profile);
      fsIdentify({
        id: getters.profilePid,
        data: profile,
      });
    } catch (error) {
      throw error;
    } finally {
      commit('setProfileLoading', false);
    }
  },

  async updateProfile({ commit }, updates) {
    commit('setProfileUpdating', true);
    try {
      const profile = await api().put('cg/info', updates);
      commit('setProfile', cloneDeep(profile));
    } catch (error) {
      throw error;
    } finally {
      commit('setProfileUpdating', false);
    }
  },

  async getNextRoute({}, hash) {
    try {
      return await api().get('cg/next?hash=' + hash);
    } catch (error) {
      throw error;
    }
  },
};
