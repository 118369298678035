import Module from '@/store/resource/common/module';
import Org from '@/store/resource/org';
import api from '@/factories/api';

let module = new Module(new Org());

import qs from 'qs';

module.add('state', {
  featureUsageLoaded: false,
  featureUsage: {
    campaigns: null,
    workflows: null,
    workflow_size: null,
    sms: null,
    users: null,
  },
  orgBilling: null,
});

module.add('actions', {
  async getOrgSettings() {
    try {
      return await api().get('org');
    } catch (err) {
      throw err;
    }
  },

  async updateOrgSettings({}, data) {
    try {
      return await api().put('org', data);
    } catch (err) {
      throw err;
    }
  },

  async getAllFeaturesUsage({ commit }) {
    try {
      const res = await api().get(`org/usage`);
      commit('setAllFeaturesUsage', res);
      return res;
    } catch (err) {
      throw err;
    }
  },

  async getFeatureUsage({ commit }, params) {
    try {
      const strParams = qs.stringify(params);
      const res = await api().get(`org/usage?${strParams}`);
      commit('setFeatureUsage', {
        type: params.type,
        usage: res[params.type],
      });
      return res[params.type];
    } catch (err) {
      throw err;
    }
  },

  async getOrgBilling({ commit }, shareToken) {
    commit('loading');
    try {
      const billing = await api({ shareToken }).get('org/billing');
      commit('setOrgBilling', billing);
    } catch (err) {
      throw err;
    } finally {
      commit('loading', false);
    }
  },

  async updateOrgBilling({ dispatch }, { stripeToken, sharedToken }) {
    try {
      await api({
        params: { token: sharedToken },
      }).put('org/billing', { token: stripeToken });
      dispatch('getOrgBilling');
    } catch (err) {
      throw err;
    }
  },

  async notifyAboutBilling(_, user_ids) {
    if (!user_ids.length) return;

    await api().post('org/billing_notify', { user_ids });
  },

  async updateOrgPlan({ commit }, data) {
    return await api().put('org/plan', data);
  },

  async getOrgInvoices({}) {
    try {
      const data = await api().get('org/invoices');
      return data?.invoices || [];
    } catch (err) {
      throw err;
    }
  },
});

module.add('mutations', {
  setFeatureUsage(state, data) {
    const { type, usage } = data;
    state.featureUsage[type] = usage;
  },
  setAllFeaturesUsage(state, data) {
    state.featureUsage = data;
    state.featureUsageLoaded = true;
  },
  setOrgBilling(state, data) {
    state.orgBilling = data;
  },
});

export default module.create();
