import api from '@/factories/api';
import { cloneDeep } from 'lodash';

class Resource {
  path = '';

  defaults() {
    return {};
  }

  fullPath(path = '') {
    return this.path + (path ? '/' + path : '');
  }

  prepData(data) {
    data = cloneDeep(data);
    if (data._dirty) {
      delete data._dirty;
    }
    return data;
  }

  /**
   * get() allows for this.get({ key : 'value' })
   * or this.get('route') where route = /path/route
   * @param  Mixed path   string to path or can be params
   * @param  Mixed params object of params or null
   * @return Promise
   */
  async get(path, params = null) {
    if (typeof path === 'object') {
      params = path;
      path = '';
    }

    path = this.fullPath(path);

    return this.request(async () => {
      const data = params ? { params } : null;
      return api().get(path, data);
    });
  }

  async update(path, data) {
    if (typeof path === 'object') {
      data = path;
      if (data.id) {
        path = data.id;
      }
    }

    path = this.fullPath(path);
    data = this.prepData(data);

    return this.request(async () => {
      return api().put(path, data);
    });
  }

  async create(path, data) {
    if (typeof path === 'object') {
      data = path;
      path = '';
    }

    path = this.fullPath(path);
    data = this.prepData(data);

    return this.request(async () => {
      return api().post(path, data);
    });
  }

  async delete(path) {
    if (typeof path === 'object') {
      if (path.id) {
        path = path.id;
      }
    }

    path = this.fullPath(path);
    return this.request(async () => {
      return api().delete(path);
    });
  }

  async request(req) {
    try {
      let res = await req();

      return {
        ok: true,
        data: res || null,
      };
    } catch (err) {
      throw err;
    }
  }
}

export default Resource;
