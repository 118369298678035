<template>
  <v-menu
    offsetY
    :offsetX="false"
    :right="isDirection('right')"
    :left="isDirection('left')"
    content-class="item-card__dropdown"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        @click.native.stop.prevent
        fab
        small
        icon
        v-on="on"
        :color="iconColor"
        :class="buttonsClass"
        :disabled="disabled"
      >
        <v-icon>more_vert</v-icon>
      </v-btn>
    </template>

    <slot />
  </v-menu>
</template>

<script>
export default {
  name: 'DropdownMenuButton',
  props: {
    iconColor: {
      type: String,
      default: 'appMain',
    },
    buttonsClass: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    direction: {
      type: String,
      default: 'left',
    },
  },
  methods: {
    isDirection(direction) {
      return direction === this.direction;
    },
  },
};
</script>
